import produce from 'immer'
import { FLUSH_PDF_METADATA, LOGIN, LOGOUT, SET_PDF_METADATA, SET_USER, UPDATE_USER } from './constants'

const initialState = {
  pdfMetadata: {},
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN:
        draft.token = action.token
        break
      case SET_USER:
        draft.user = action.user
        break
      case UPDATE_USER:
        draft.user = { ...draft.user, ...action.user }
        break
      case LOGOUT:
        return initialState
      case FLUSH_PDF_METADATA:
        draft.pdfMetadata = {}
        break
      case SET_PDF_METADATA:
        draft.pdfMetadata = action.pdfMetadata
        break
      default:
        break
    }
  })

export default reducer
